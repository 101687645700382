import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyFeedCarousel = lazy(
  () => import("./feed-carousel-C5cxIYrl.js").then((module) => ({
    default: module.FeedCarousel
  }))
);
function FeedCarousel({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyFeedCarousel, { ...props });
}
const useFeedCarouselContent = () => {
  return useMemo(
    () => ({
      feedCarousel: {
        component: FeedCarousel
      }
    }),
    []
  );
};
export {
  LazyFeedCarousel as FeedCarousel,
  useFeedCarouselContent
};
